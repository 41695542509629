import * as PropTypes from "prop-types";

import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";

import styleSelf from "./frontpageslug.module.css";
import BackgroundImage from "gatsby-background-image";

class FrontpageSlug extends React.Component {
  static propTypes = {
    node: PropTypes.shape({
      frontpage_keyart: PropTypes.object,
      id: PropTypes.string.isRequired
    }).isRequired
  };
  constructor() {
    super();
    this.state = {
      hovering: false
    };
  }
  render() {
    const props = this.props;

    //if(this.props.node.frontpage_override)
    // {
    //  let linkStart =
    //</Link> }

    if (this.props.node.frontpage) 
    {
      return (
        <BackgroundImage
          fluid={props.node.frontpage_keyart.childImageSharp.fluid}
          title={props.node.name}
          className={styleSelf.masterContainer}
        >
          {(props.node.frontpage_override != "none") ? (
            <a href={props.node.frontpage_override}>
              <div className={styleSelf.contentContainer}>
                <div>
                  <Img
                    fluid={props.node.frontpage_logo.childImageSharp.fluid}
                    alt={props.node.name}
                    className={styleSelf.logo}
                  />
                </div>
                <div className={styleSelf.blurb}>
                  {props.node.selltext_short}
                </div>
              </div>
            </a>
          ) : (
            <Link to={"/" + props.node.urlslug}>
              <div className={styleSelf.contentContainer}>
                <div>
                  <Img
                    fluid={props.node.frontpage_logo.childImageSharp.fluid}
                    alt={props.node.name}
                    className={styleSelf.logo}
                  />
                </div>
                <div className={styleSelf.blurb}>
                  {props.node.selltext_short}
                </div>
              </div>
            </Link>
          )}
        </BackgroundImage>
      );
    }
  }
}

export default FrontpageSlug;

export const postFragment = graphql`
  fragment FrontpageSlug_details on ProjectsJson {
    id
    name
    urlslug
    selltext_short
    frontpage
    frontpage_override

    frontpage_keyart {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
